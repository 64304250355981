@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scale-up {
  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.blk-loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 0px;
  width: 100%;
}

.blk-loading-component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 0px;
  width: 80%;
}

.blk-loading-message {
  opacity: 0;
  animation: fade-in 0.4s ease-in-out 0.4s forwards;
}

.blk-loading {
  position: relative;
  width: 100px;
  height: 100px;
  transform: scale(0);
  animation: scale-up 1s ease-in-out forwards;
}

.blk-loading .blk-loading-large {
  position: absolute;
  -webkit-animation: lds-eclipse 0.4s reverse infinite;
  animation: lds-eclipse 0.4s reverse infinite;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #ea0269;
  -webkit-transform-origin: 80px 82px;
  transform-origin: 50% 50%;
}

.blk-loading .blk-loading-small {
  position: absolute;
  -webkit-animation: lds-eclipse 0.6s linear infinite;
  animation: lds-eclipse 0.6s linear infinite;
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 -2px 0 0 #ea0269;
  -webkit-transform-origin: 80px 82px;
  transform-origin: 50% 50%;
}

.blk-loading-logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.blk-loading-logo svg {
  width: 35px;
  fill: #ea0269;
}

.blk-loading-message {
  text-align: center;
  color: #6b6d70;
  font-weight: 100;
  font-size: 18px;
  padding-top: 14px;
  line-height: 1.5;
}
