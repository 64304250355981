* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #000000;
}

html, body, #root{
  height: 100%;
}

a,
div:not(.material-icons),
table,
tr,
td,
span:not(.material-icons),
p,
b,
li,
ul,
label,
small {
  font-family: 'Roboto', sans-serif;
}

.mdi-icon {
  fill: currentColor;
}

.hide {
  display: none !important;
}

[class*=' MuiButton']:not([class*=' MuiButton-fab']):not(.icon-right) .mdi-icon {
  margin-right: 10px;
}

.MuiCard-root {
  overflow: visible !important;
}

.headerTitle {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}

[class*='MuiDialogContent'] {
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.button-margin-input {
  margin-top: 25px !important;
}

/* [class*='MuiModal-'] {
  overflow: auto !important;
} */

.pb-8 {
  padding-bottom: 8px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.page-break {
  page-break-before: always;
  padding-top: 2em;
}

.eduzz-toolbar1 {
  z-index: 5000 !important;
}


.noprint2 {
  display: none;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .noprint {
    visibility: hidden;
    position: absolute;
  }

  html,
  body {
    margin: 0 !important;
    margin-top: 10px !important;
    padding: 0 !important;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}